<template>
  <section id="suscripcion-crear" v-if="$store.state.appConfig.rol == 3">
    <b-col cols="14">
      <b-card>
        <!-- Header -->
        <b-card-header class="px-0 py-0">
          <div class="container p-0">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-0">Editar Link de Pago</h4>
              </div>
            </div>
          </div>
        </b-card-header>
      </b-card>
    </b-col>

    <b-row>
      <b-col class="ml-2">
        <h4>Información general</h4>
        <span>
          En esta sección puedes agregar la información básica de tu smartlink.
          Escribe una descripción para familiarizar a tus clientes del producto
          o servicio.
        </span>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <b-form class="mt-1">
              <b-row>
                <!-- Field: Producto o Servicio -->
                <b-col cols="12" md="6" lg="12">
                  <b-form-group
                    label="Producto o Servicio *"
                    label-for="producto"
                  >
                    <b-form-input
                      id="producto"
                      v-model="producto"
                      :state="aceptadoProducto"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: Email -->
                <b-col cols="12" md="6" lg="12">
                  <b-form-group
                    label="Texto promocional *"
                    label-for="texto-promocional"
                  >
                    <b-form-input
                      id="texto-promocional"
                      v-model="textoPromocional"
                      :state="aceptadoTextoProm"
                    />
                  </b-form-group>

                  <div v-if="textoPromocional != null">
                    <label
                      v-if="textoPromocional.length <= 100"
                      class="font-italic"
                      >Hasta 100 carácteres</label
                    >
                    <label
                      v-if="textoPromocional.length > 100"
                      class="font-italic"
                      style="color: red !important"
                      >Te has excedido de caracteres</label
                    >
                  </div>
                </b-col>

                <b-col cols="12" md="6" lg="12">
                  <b-form-group
                    label="Descripción del producto o servicio *"
                    label-for="descripcion"
                  >
                    <b-form-textarea
                      id="descripcion"
                      v-model="descripcion"
                      rows="3"
                      max-rows="6"
                      :state="aceptadoDescripcion"
                    ></b-form-textarea>
                  </b-form-group>
                  <div v-if="descripcion != null">
                    <label v-if="descripcion.length <= 500" class="font-italic"
                      >Hasta 500 carácteres</label
                    >
                    <label
                      v-if="descripcion.length > 500"
                      class="font-italic"
                      style="color: red !important"
                      >Te has excedido de caracteres</label
                    >
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="ml-2">
        <h4>Imagen</h4>
        <span> Agrega una imagen que identifique tu producto o servicio. </span>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <b-form class="mt-1">
              <b-row>
                <!-- Field: Image -->
                <b-col cols="12" md="6" lg="7">
                  <b-form-group
                    label="Imagen de producto o servicio *"
                    label-for="image"
                    class="mb-0"
                  >
                    <div class="custom-file">
                      <input
                        @change="upload"
                        type="file"
                        id="archivo"
                        lang="es"
                        class="file"
                        name="archivo"
                        :state="aceptadoImagen"
                      />
                      <label class="custom-file-label" for="archivo">{{
                        nombreArchivo
                      }}</label>
                    </div>
                    <div v-if="!aceptadoImagen">
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="16"
                        class="m-0 p-0"
                        style="color: red"
                      />
                      <label style="color: red !important; margin-left: 8px"
                        >Campo obligatorio. Formato inválido</label
                      >
                    </div>
                  </b-form-group>
                  <label class="font-italic"
                    >Resolución sugerida 1200 x 1200. Tamaño máx 4mb .png,
                    .jpg</label
                  >
                </b-col>
              </b-row>
              <div>
                <b-alert
                  v-height-fade.appear
                  :show="dismissCountDownImage"
                  dismissible
                  class="my-1"
                  variant="danger"
                  id="invalid-image-popup"
                  @dismissed="dismissCountDownImage = 0"
                  @dismiss-count-down="countDownChangedImage"
                >
                  <div class="alert-body">
                    <span class="bold">Error al cargar imagen </span>
                    <span>Formato inválido, intente nuevamente</span>
                  </div>
                </b-alert>
              </div>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
     <b-row>
      <b-col class="ml-2 mb-2">
        <h4>URL Callback</h4>
        <span> Agrega un sitio al cual redireccionar una vez realizado el pago. </span>
      </b-col>
      <b-col cols="md-9 12">
        <b-card>
          <div>
            <!-- Form -->
            <b-form class="mt-1">
              <b-row>
                <!-- Field: Image -->
                <b-col cols="12" md="6" lg="7">
                  <b-form-group
                    label="Url de redireccion (opc)."
                    class="mb-0"
                  >
                    <div class="custom-file">
                      <b-form-input
                        v-model="callbackUrl"
                        type="text"
                        placeholder="http:// o https://"
                        id="urlcallback"
                        lang="es"
                      />
                      
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="ml-2">
        <h4>Validez</h4>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <b-form class="mt-1">
              <b-row>
                <!-- Field: Fecha lanzamiento -->
                <b-col cols="6">
                  <b-form-group
                    label="Fecha de lanzamiento"
                    label-for="lanzamiento"
                  >
                    <b-form-radio-group
                      v-model="stateLanzamiento"
                      aria-controls="ex-disabled-readonly"
                      @change="lanzamientoFecha = new Date()"
                    >
                      <div class="row ml-1 mt-1">
                        <b-form-radio
                          v-model="lanzamiento"
                          class="col-5 custom-control-info"
                          value="enabled"
                          >Sí</b-form-radio
                        >
                        <b-form-radio
                          v-model="lanzamiento"
                          class="col-5 custom-control-info"
                          value="disabled"
                          >No</b-form-radio
                        >
                      </div>
                    </b-form-radio-group>
                    <b-form-datepicker
                      id="lanzamiento-datepicker"
                      v-model="lanzamientoFecha"
                      class="btn-filtro ml-1 mt-1 ancho-date-picker"
                      selected-variant="info"
                      button-variant="btn-info"
                      :min="lanzamientoMinimo"
                      :max="expiracionFecha"
                      value=""
                      label-no-date-selected=""
                      nav-button-variant="info"
                      :disabled="disabledLanzamiento"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback style="color: red" v-if="errorDate">
                    La fecha inicial debe ser menor a la fecha final.
                  </b-form-invalid-feedback>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Fecha de expiración"
                    label-for="expiracion"
                  >
                    <b-form-radio-group
                      v-model="stateExpiracion"
                      aria-controls="ex-disabled-readonly"
                    >
                      <div class="row ml-1 mt-1">
                        <b-form-radio
                          v-model="expiracion"
                          class="col-5 custom-control-info"
                          value="enabled"
                          >Sí</b-form-radio
                        >
                        <b-form-radio
                          v-model="expiracion"
                          class="col-5 custom-control-info"
                          value="disabled"
                          @click="expiracion = null"
                          >No</b-form-radio
                        >
                      </div>
                    </b-form-radio-group>

                    <b-form-datepicker
                      id="expiracion-datepicker"
                      v-model="expiracionFecha"
                      class="btn-filtro ml-1 mt-1 ancho-date-picker"
                      selected-variant="info"
                      button-variant="btn-info"
                      value="expiracion"
                      :min="
                        stateLanzamiento == 'disabled'
                          ? lanzamientoMinimo
                          : lanzamientoFecha
                      "
                      label-no-date-selected=""
                      nav-button-variant="info"
                      :disabled="disabledExpiracion"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div >
                <b-form-checkbox
                  v-model="permanent"
                  class="custom-control-info"
                >
                  Este link puede usarse más de una vez
                </b-form-checkbox>
              </div>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="ml-2">
        <h4>Precio</h4>
        <span> Configura el importe a cobrar por tu producto o servicio. </span>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <b-form class="mt-1">
              <b-row>
                <!-- Field: Columna izquierda -->
                <b-col cols="6">
                  <b-form-group label="Tipo de Pago *" label-for="tipoDePago">
                    <div class="row ml-1 mt-1">
                      <b-form-radio
                        v-model="tipoPago"
                        class="col-6 custom-control-info"
                        value="1"
                        @change="verDatos"
                        >Pago Único</b-form-radio
                      >
                      <b-form-radio
                        v-model="tipoPago"
                        @change="verDatos"
                        class="col-6 custom-control-info"
                        value="2"
                        >Suscripción</b-form-radio
                      >
                    </div>
                  </b-form-group>
                  <div v-if="!aceptadoTipoPago">
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="16"
                      class="m-0 p-0"
                      style="color: red"
                    />
                    <label style="color: red !important; margin-left: 8px"
                      >Campo obligatorio</label
                    >
                  </div>
                  <b-form-group
                    label="Importe *"
                    label-for="importe"
                    class="mt-1"
                  >
                    <b-form-input
                      id="importe"
                      v-model="importe"
                      type="number"
                      :state="aceptadoImporte"
                    />
                  </b-form-group>

                  <!-- <b-form-group
                    label="Moneda *"
                    label-for="moneda"
                    class="mt-1"
                    :state="aceptadoMoneda"
                  >
                    <v-select
                      v-model="moneda"
                      :dir="dir"
                      label="title"
                      :options="option"
                      :state="aceptadoMoneda"
                    />
                  </b-form-group> -->
                  <div v-if="tipoPago == 2">
                    <b-form-group
                      label="Periodicidad *"
                      label-for="periodicidad"
                      class="mt-1"
                      :state="aceptadoPeriodicidad"
                    >
                      <v-select
                        v-model="periodicidad"
                        :dir="dir"
                        label="title"
                        value="value"
                        :options="optionPeriodicidad"
                        :state="aceptadoPeriodicidad"
                      />
                    </b-form-group>
                    <b-form-checkbox
                      value="selected"
                      v-model="diasPruebaActive"
                      class="custom-control-info"
                    >
                      Habilitar días de prueba
                    </b-form-checkbox>
                    <div v-if="diasPruebaActive">
                      <b-form-group
                        label="Elige la cantidad de días *"
                        label-for="diasPrueba"
                        class="mt-1"
                        :state="aceptadoDiasPrueba"
                      >
                        <v-select
                          v-model="diasPrueba"
                          value="value"
                          label="text"
                          :dir="dir"
                          :options="optionDiasPrueba"
                          :state="aceptadoDiasPrueba"
                        />
                      </b-form-group>
                    </div>
                  </div>
                    <b-form-checkbox
                    v-model="descuentoEnabled"
                    class="custom-control-info flex-justify-content-center"
                    
                  >
                    <label class="text-card-detail" 
                      >Habilitar cupones de descuento</label
                    >
                  </b-form-checkbox>
                </b-col>
                <!-- Field: Columna derecha -->
                <b-col>
                  <div id="card-monto">
                    <h4 class="mb-3">Resumen de la compra</h4>
                    <hr />
                    <b-row>
                      <b-col>
                        <h4 class="mt-2">Total</h4>
                      </b-col>
                      <b-col>
                        <h4 class="mt-2 text-right">
                          {{ currencyString }} {{ importe }}
                        </h4>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="ml-2">
        <h4>Pasarelas</h4>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <b-form>
              <div class="row margen-medio-pago">
                <b-form-checkbox
                  v-for="(medio, index) in optionPasarelas"
                  :key="index"
                  v-model="pasarelas"
                  :value="medio.value"
                  class="custom-control-info col-3"
                >
                  {{ medio.text }}
                </b-form-checkbox>
              </div>
              <div v-if="!aceptadoPasarelas">
                <feather-icon
                  icon="AlertCircleIcon"
                  size="16"
                  class="m-0 p-0"
                  style="color: red"
                />
                <label style="color: red !important; margin-left: 8px"
                  >Campo obligatorio</label
                >
              </div>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="!(pasarelas == ![])">
      <b-col class="ml-2">
        <h4>Medio de pago</h4>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <b-form>
              <div class="row margen-medio-pago">
                <b-form-checkbox
                  v-for="(medio, index) in showMediosPago"
                  :key="index"
                  v-model="mediosPago"
                  :value="medio.value"
                  class="custom-control-info col-3"
                >
                  <!-- {{pasarelas}}-{{medio.pasarela}} -->
                  {{ medio.text }}
                </b-form-checkbox>
              </div>
              <div v-if="!aceptadoMedioPago">
                <feather-icon
                  icon="AlertCircleIcon"
                  size="16"
                  class="m-0 p-0"
                  style="color: red"
                />
                <label style="color: red !important; margin-left: 8px"
                  >Campo obligatorio</label
                >
              </div>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="ml-2">
        <h4>Términos y <br />Condiciones</h4>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <b-form>
              <b-form-radio-group
                v-model="stateTerminosCondiciones"
                aria-controls="ex-disabled-readonly"
              >
                <div class="mb-1 margen-medio-pago">
                  <b-form-radio
                    v-model="terminosCheck"
                    class="col-3 custom-control-info"
                    @change="verTerminos(terminosCheck)"
                    value="disabled"
                    >Predeterminado</b-form-radio
                  >
                  <b-form-radio
                    v-model="terminosCheck"
                    class="col-3 custom-control-info"
                    @change="verTerminos(terminosCheck)"
                    value="enabled"
                    >Personalizado</b-form-radio
                  >
                </div>
              </b-form-radio-group>

              <b-form-group
                label="Términos y Condiciones *"
                label-for="termsCondiciones"
              >
                <b-form-textarea
                  id="termsCondiciones"
                  v-model="terminosCondiciones"
                  rows="8"
                  :disabled="disabledTerminosCondiciones"
                  :state="aceptadoTerminosCondiciones"
                ></b-form-textarea>
              </b-form-group>
              <div v-if="terminosCondiciones != null">
                <label
                  v-if="
                    terminosCondiciones.length <= 1000 &&
                      terminosCondiciones.length > 1000
                  "
                  class="font-italic"
                  >Hasta 1000 carácteres</label
                >
                <label
                  v-if="terminosCondiciones.length == 0"
                  class="font-italic"
                  style="color: red !important"
                  >Campo Obligatorio</label
                >
                <label
                  v-if="terminosCondiciones.length > 1000"
                  class="font-italic"
                  style="color: red !important"
                  >Te has excedido de caracteres</label
                >
              </div>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="ml-2">
        <h4>Formulario Dinámico</h4>
        <span>
          Crea un formulario arrastrando los componentes para obtener los datos
          que necesitas de tus clientes.
        </span>
      </b-col>
      <b-col cols="9">
        <b-card>
          <div>
            <!-- Form -->
            <!-- <b-form> -->
            <input id="fileUpload" type="file" hidden />
            <b-button
              id="choose-template-button"
              variant="primary"
              class="margin-cancel"
              @click="showSavedTemplates()"
            >
              Elegir plantilla
            </b-button>
            <b-button
              id="choose-empty-template-button"
              class="mr-1"
              :variant="isVisible ? 'outline-primary' : 'primary'"
              @click="showDynamicForm()"
            >
              Elegir plantilla en blanco
            </b-button>

            <div>
              <b-alert
                v-height-fade.appear
                :show="dismissCountDownTemplate"
                dismissible
                class="my-1"
                variant="danger"
                id="invalid-template-popup"
                @dismissed="dismissCountDownTemplate = 0"
                @dismiss-count-down="countDownChangedTemplate"
              >
                <div class="alert-body">
                  <span class="bold">Error al cargar plantilla </span>
                  <span>Intente nuevamente</span>
                </div>
              </b-alert>
            </div>

            <div :class="{ 'd-block': isVisible, 'd-none': !isVisible }">
              <b-row class="mt-3">
                <b-col cols="3 px-0 mr-1">
                  <h4 class="font-weight-bolder ml-1" id="label-campos">
                    Campos
                  </h4>
                  <b-col md="6">
                    <draggable
                      :list="list1"
                      :group="{ name: 'tags', pull: 'clone', put: false }"
                      class="mb-1"
                    >
                      <b-button
                        v-for="(listItem, index) in list1"
                        :key="index"
                        class="dynamic-form-buttons mb-1"
                        :variant="
                          isAdded(listItem) ? 'outline-secondary' : 'secondary'
                        "
                        :disabled="isAdded(listItem)"
                        @dragleave="showList2"
                      >
                        {{ listItem.title }}
                      </b-button>
                    </draggable>
                  </b-col>
                </b-col>
                <b-col>
                  <b-row align-v="center" align-h="between">
                    <b-col>
                      <h4 class="font-weight-bolder formulario-label">Formulario</h4>
                    </b-col>
                    <b-col cols="4" style="text-align: end">
                      
                    </b-col>
                  </b-row>

                  <div id="card-dynamic-form" class="border-dark">
                    <draggable
                      :list="list2"
                      :group="{ name: 'tags' }"
                      class="mb-1"
                      style="min-height: 480px"
                      @change="onUnpublishedChange"
                    >
                      <div class="row">
                        <div
                          v-for="(listItem, index) in list2"
                          :key="index"
                          :class="listItem.col"
                        >
                          <div :id="listItem.title" class="card mb-1">
                            <div class="card-body py-1">
                              <b-row align-h="end" align-v="end">
                                <b-col>
                                  <label>{{ listItem.title }}</label>
                                </b-col>
                                <b-col style="text-align: end">
                                  <div
                                    v-on:click="chooseColums(listItem)"
                                    class="btn no-padding"
                                  >
                                    <feather-icon
                                      icon="ColumnsIcon"
                                      size="18"
                                    />
                                  </div>

                                  <button
                                    class="btn no-padding"
                                    @click="deleteComponent(listItem)"
                                  >
                                    <feather-icon icon="Trash2Icon" size="18" />
                                  </button>
                                </b-col>
                              </b-row>
                              <b-form-group class="mb-0">
                                <b-form-input v-id="listItem.title" />
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </b-col>
              </b-row>
            </div >
            <div :class="{ 'd-block': isVisible, 'd-none': !isVisible }">
              <div class="row">
                <div class="col-6"></div>
                <div class="col-6" style="text-align: end">
                  <b-button
                        class="buttonGuardarPlantilla"
                        :variant="isVisible ? 'primary' : 'outline-primary'"
                        @click="handleField"
                        :disabled="list2.length === 0 || disableButtonSaveTemplate || buttonDisabled "
                      >
                        Guardar plantilla
                      </b-button>
                </div>
              </div>
              
            </div>
            <!-- </b-form> -->
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-v="end">
      <b-col></b-col>
      <b-col cols="9">
        <b-card>
          <!-- botones -->
          <div>
            <b-alert
              v-height-fade.appear
              :show="dismissCountDown"
              dismissible
              class="mb-1 ml-1"
              variant="warning"
              id="empty-field-popup"
              @dismissed="dismissCountDown = 0"
            >
              <div class="alert-body">
                <span class="bold">Advertencia! </span>
                <span>Debes completar los campos obligatorios </span>
              </div>
            </b-alert>
          </div>
          <div class="row align-items-center">
            <div class="col justify-content-end">
              <div style="display: flex">
                <div style="margin-left: auto">
                  <b-button
                    id="cancel-button"
                    variant="outline-primary"
                    class="mr-1"
                    @click="modalCancelarShow = !modalCancelarShow"
                    v-b-modal.modal-Cancelar
                    ><!-- <div id="loading-bg" v-if="loading">
                      <div class="loading">
                        <div class="effect-1 effects"></div>
                        <div class="effect-2 effects"></div>
                        <div class="effect-3 effects"></div>
                      </div>
                    </div> -->
                    Cancelar
                  </b-button>

                  <router-link
                    :to="{
                      name: 'misc-link-pago-prev',
                    }"
                    target="_blank"
                  >
                    <b-button
                      id="cancel-button"
                      variant="outline-primary"
                      class="mr-1"
                      @click="saveList()"
                    >
                      Previsualizar
                    </b-button>
                  </router-link>
                  <b-button
                    :disabled="
                      !isNewTemplateActive || $store.state.appConfig.loading
                    "
                    variant="primary"
                    class="shadow-none"
                    id="button-guardar"
                    @click="validateEmptyFields()"
                  >
                    Guardar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-Cancelar"
      body-bg-variant="light-warning"
      v-model="modalCancelarShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-Cancelar"
    >
      <label id="label-confirmacion-cancelar">
        ¿ Seguro que quieres cancelar?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideCancelarModal"
          >
            No
          </b-button>
          <router-link to="/dashboard/linksPago">
            <b-button
              variant="outline-warning"
              class="modal-button-size shadow-none"
              id="agregar-button-modal"
            >
              Sí
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal Elegir plantilla -->
    <b-modal
      id="modal-ElegirPlantilla"
      v-model="modalElegirPlantillaShow"
      ref="modal-ElegirPlantilla"
      ok-only
      ok-title="Aceptar"
      @ok="confirmTemplate"
      centered
      title="Elige una plantilla"
    >
      <b-form-select
        v-model="selectedPlantilla"
        :options="optionsPlantilla"
        v-on:change="showSelectionFields"
      />
      <div class="mt-1">Campos:</div>

      <div v-for="(item, index) in selectionFields" :key="index">
        <strong>{{ item.name }}</strong>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BTable,
  BFormDatepicker,
  BModal,
  BFormRadio,
  BAlert,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BBadge,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import Button from '@/views/components/button/Button.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import { backendUrl } from '@/config';
import axios from 'axios';
import BadgeBlockVue from '@/views/components/badge/BadgeBlock.vue';
import { BFormSelect } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BTable,
    Button,
    BFormDatepicker,
    BModal,
    BFormRadio,
    ToastificationContent,
    BAlert,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    draggable,
    BBadge,
    Prism,
    BFormSelect,
  },
  created() {
    //this.cargarProyecto();
    this.getProducts();
    this.$store.commit('appConfig/changeLoading', true);
  },
  mounted() {
    if (
      this.$store.state.appConfig.rol != 3 ||
      this.$store.state.appConfig.rol == 0
    ) {
      this.$router.push({ path: '/' });
    } else {
      this.cargarLink();
 
      // this.getProducts();
    }

    this.getSavedTemplates();
  },
  methods: {
    verificaPasarela() {
      var me = this;
      if(me.optionMediosPago.length == 0){
        me.optionMediosPago = [
          { text: 'Tarjeta de Crédito', value: 1, pasarela: [1, 2] },
          { text: 'Tarjeta de Débito', value: 2, pasarela: [1] },
          { text: 'Efectivo', value: 3, pasarela: [1] },
          { text: 'Transferencia', value: 4, pasarela: [1] },
          { text: 'Entel', value: 5, pasarela: [3] },
          { text: 'Movistar', value: 6, pasarela: [3] },
        ]
      }

      
      var result = me.optionMediosPago.filter((x) => {
        let bool = false;
        let bool2 = false;

        me.pasarelas.map((y) => {
          if (x.pasarela.indexOf(y) > -1) bool = true;
        });
        if (x.value === 1 || x.value === 2) {
          bool2 = me.proyectoActual.card_enable === 1;
        } else if (x.value === 3) {
          bool2 = me.proyectoActual.cash_enable === 1;
        } else if (x.value === 4) {
          bool2 = me.proyectoActual.trasfer_enable === 1;
        } else if(x.value === 5) {
          bool2 = me.proyectoActual.entel_enable === 1;
        } else if(x.value === 6) {
          bool2 = me.proyectoActual.movistar_enable === 1;
        }
        if (bool && bool2) return x;
      });
     
      return result;
    },
    async getUtils() {
      const token = localStorage.getItem('token');

      var config = {
        method: 'get',
        url: `${backendUrl}/operaciones/util`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resUtils = await axios(config);

        const { pasarelas } = resUtils.data.response;

        for (let i = 0; i < this.productoActivo.credential.length; i++) {
          const nombreCredencial = pasarelas.filter(
            (e) => e.id === this.productoActivo.credential[i].id_gateway
          );

          this.optionPasarelas.push({
            text: nombreCredencial[0].nombre,
            value: nombreCredencial[0].id,
          });
        }
      } catch (error) {
      }
    },
    async getProducts() {
      const token = localStorage.getItem('token');

      var config = {
        method: 'get',
        url: `${backendUrl}/productos/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const resProduct = await axios(config);

      let { project, credential, currency } = resProduct.data;

      let productosArray = [];

      for (let i = 0; i < project.length; i++) {
        const dataCredential = await credential.filter(
          (e) => e.id_project === project[i].id
        );
        const dataCurrency = await currency.filter(
          (e) => e.id_project === project[i].id
        );

        project[i].credential = dataCredential;
        project[i].currency = dataCurrency;

        productosArray.push(project[i]);
      }

      const productoActive = productosArray.filter(
        (e) => e.id === this.$store.state.appConfig.projecto.id
      );
      this.productoActivo = productoActive[0];


      this.getUtils();
    },
    verTerminos(value) {
      if (value == 'disabled') {
        this.terminosCondiciones = this.productoActivo.Terms_and_Conditions;
      } else {
        this.terminosCondiciones = this.linkData.Terms_and_Conditions;
      }
      
    },
    showList2() {
    },
    saveList() {
      localStorage.setItem('list2', JSON.stringify(this.list2));
      localStorage.setItem('productoNombre', JSON.stringify(this.producto));
      localStorage.setItem(
        'textoPromocional',
        JSON.stringify(this.textoPromocional)
      );
      localStorage.setItem('descripcion', JSON.stringify(this.descripcion));
      localStorage.setItem('importe', JSON.stringify(this.importe));
      localStorage.setItem('moneda', JSON.stringify(this.moneda));
      localStorage.setItem('pasarelas', JSON.stringify(this.pasarelas));
      localStorage.setItem('terms', JSON.stringify(this.terminosCondiciones));
    },
    hideEditarModal() {
      this.$refs['modal-editar'].hide();
    },
    hideAgregarModal() {
      this.$refs['modal-agregar'].hide();
    },
    hideEliminarModal() {
      this.$refs['modal-eliminar-tarifa'].hide();
    },
    hideCancelarModal() {
      this.$refs['modal-Cancelar'].hide();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    countDownChangedImage(dismissCountDownImage) {
      this.dismissCountDownImage = dismissCountDownImage;
    },
    showAlertInvalidImageUpload() {
      this.dismissCountDownImage = this.dismissSecsImage;
    },
    countDownChangedImage(dismissCountDownTemplate) {
      this.dismissCountDownTemplate = dismissCountDownTemplate;
    },
    showAlertInvalidTemplateUpload() {
      this.dismissCountDownTemplate = this.dismissSecsTemplate;
    },
    showLinkCreadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha editado el Link de Pago exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showLinkCreadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Editar el Link de Pago',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    verDatos() {
      this.tipoPago = tipoPago.value;
    },
    showPrevisualizarErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Previsualizar el formulario',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showCargarFormularioErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Cargar el formulario',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    validateEmptyFields() {

      this.$store.commit('appConfig/changeLoading', true);
      /*  if (
        this.stateLanzamiento == 'enabled' &&
        this.stateExpiracion == 'enabled'
      ) {
        this.validateDates();
      } else  */ if (
        this.stateLanzamiento == 'enabled' &&
        this.lanzamientoFecha == null
      ) {
        this.errorDate = true;
      } else if (
        this.stateExpiracion == 'enabled' &&
        this.expiracionFecha == null
      ) {
        this.errorDate = true;
      }
      var boolErrorDate = !this.errorDate;
      var boolProducto = this.producto != null;
      var boolTextoPromocional = this.textoPromocional != null;
      var boolDescripcion = this.descripcion != null;
      var boolImagen = this.imagen != null;
      var boolTipoPago = this.tipoPago != null;
      var boolImporte = this.importe != null;
      var boolMoneda = this.moneda != null;
      var boolPasarelas = this.pasarelas.length > 0;
      var boolMediosPago = this.mediosPago.length > 0;
      var boolSuscripcion = false;
      if (this.tipoPago == 2 && this.periodicidad != null) {
        if (this.diasPruebaActive && this.diasPrueba == '')
          boolSuscripcion = false;
        else if (this.diasPruebaActive && this.diasPrueba != '')
          boolSuscripcion = true;
        else if (!this.diasPruebaActive) boolSuscripcion = true;
      } else if (this.tipoPago == 1) {
        boolSuscripcion = true;
      }

      if((!this.planillaEnBlanco || !this.planillaEnBlanco.length) && !this.buttonDisabled) {
          this.showGenericToast({
            title: 'Error en la plantilla.',
            text: 'El formulario esta vacio o no lo guardo.',
            icon: 'AlertCircleIcon',
            variant: 'warning'
        });

        this.$store.commit('appConfig/changeLoading', false);
        return;
      }

      if (
        boolErrorDate &&
        boolProducto &&
        boolTextoPromocional &&
        boolDescripcion &&
        boolImagen &&
        boolTipoPago &&
        boolImporte &&
        boolMoneda &&
        boolPasarelas &&
        boolMediosPago &&
        boolSuscripcion
      ) {
        this.handleAdd();
      } else {

        this.showAlert();
        this.producto == null
          ? (this.aceptadoProducto = false)
          : (this.aceptadoProducto = true);
        this.textoPromocional == null
          ? (this.aceptadoTextoProm = false)
          : (this.aceptadoTextoProm = true);
        this.descripcion == null
          ? (this.aceptadoDescripcion = false)
          : (this.aceptadoDescripcion = true);
        this.imagen == null
          ? (this.aceptadoImagen = false)
          : (this.aceptadoImagen = true);
        this.tipoPago == null
          ? (this.aceptadoTipoPago = false)
          : (this.aceptadoTipoPago = true);
        this.importe == null
          ? (this.aceptadoImporte = false)
          : (this.aceptadoImporte = true);
        this.periodicidad == null
          ? (this.aceptadoPeriodicidad = false)
          : (this.aceptadoPeriodicidad = true);
        this.diasPrueba == ''
          ? (this.aceptadoDiasPrueba = false)
          : (this.aceptadoDiasPrueba = true);
        this.moneda == null
          ? (this.aceptadoMoneda = false)
          : (this.aceptadoMoneda = true);
        this.pasarelas.length == 0
          ? (this.aceptadoPasarelas = false)
          : (this.aceptadoPasarelas = true);
        this.mediosPago.length == 0
          ? (this.aceptadoMedioPago = false)
          : (this.aceptadoMedioPago = true);
        this.$store.commit('appConfig/changeLoading', false);
      }
    },
    chooseColums(listItem) {
      for (let i = 0; i < this.list2.length; i++) {
        if (this.list2[i].title == listItem.title) {
          this.list2[i].col =
            this.list2[i].col == 'col-12' ? 'col-6' : 'col-12';

          break;
        }


      }

    },
    deleteComponent(listItem) {
      for (let i = 0; i < this.list2.length; i++) {
        if (this.list2[i].title == listItem.title) {
          this.list2.splice(i, 1);
          this.disableButtonSaveTemplate = false;          
          this.buttonDisabled = false

          break;
        }
      }

      if(this.list2.length == 0)
        this.disableButtonSaveTemplate = false;
    },
    isAdded(listItem) {
      for (let i = 0; i < this.list2.length; i++) {
        
        if (this.list2[i].title == listItem.title) {
          this.addedNew = true
          return true;
        }
      }
      return false;
    },
    chooseFiles() {
      document.getElementById('fileUpload').this.hideDynamicForm();

      
    },
    showSavedTemplates() {
       if(!this.optionsPlantilla.length) {
        this.showGenericToast({
            title: 'No hay platillas guardadas para este producto',
            text: 'Genere una plantilla en blanco',
            icon: 'AlertCircleIcon',
            variant: 'warning'
        })

        return;
      }
      this.list2 = [];
      this.isVisible = false;
      this.modalElegirPlantillaShow = !this.modalElegirPlantillaShow;
    },
    showDynamicForm() {
      // this.list2 = [];
      this.isVisible = true;
      this.isNewTemplateActive = false;
    },
    hideDynamicForm() {
      this.isVisible = false;
    },
    validateDates() {
      if (new Date(this.lanzamientoFecha) > new Date(this.expiracionFecha)) {
        this.errorDate = true;
      } else {
        this.errorDate = false;
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    async upload() {
      const fileImage = document.getElementById('archivo').files[0].name;
      this.nombreArchivo = document.getElementById('archivo').files[0].name;
      
      if (
        fileImage.substr(fileImage.length - 4) == '.png' ||
        fileImage.substr(fileImage.length - 4) == '.jpg' ||
        fileImage.substr(fileImage.length - 5) == '.jpeg'
      ) {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('archivo', document.getElementById('archivo').files[0]);
        var config = {
          method: 'post',
          url: `${backendUrl}/link-pago/addimage/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: formData,
        };

        const resImg = await axios(config);
        this.imagen = resImg.data;
      }
    },
    async cargarLink() {
      var linkActivo = this.$store.state.appConfig.linkEditar;
      var Links = this.$store.state.appConfig.linksDePago;
      var link = Links.filter((element) => {
        if (element.id == linkActivo) return element;
      });
      this.linkData = link[0];
      this.proyectoActual = this.$store.state.appConfig.proyectosTotales.filter(
        (x) => {
          if (x.id ===  link[0].id_project) return x;
        }
      )[0];
      console.log('linkedit:::', link[0])
      this.idLink = linkActivo;
      this.producto = link[0].name;
      this.textoPromocional = link[0].textPromo;
      this.descripcion = link[0].description;
      this.tipoPago = link[0].type;
      this.imagen = link[0].url_imgage;
      this.callbackUrl = link[0].callback
      this.importe = link[0].amount;
      this.currencyString = link[0].currencyString,
      this.priceString= link[0].priceString,
      this.periodicidad = 
        link[0].periodicidad?this.optionPeriodicidad.filter((elem)=>elem.value==link[0].periodicidad):null;
      this.moneda = link[0].currency;
      this.terminosCondiciones = link[0].Terms_and_Conditions;
      this.planillaEnBlanco = link[0].field;
      console.log('thisplan', this.planillaEnBlanco)
      this.diasPruebaActive = link[0].diasPrueba != null ? 'selected' : null;
      this.diasPrueba = link[0].diasPrueba;
      this.lanzamientoFecha = new Date(link[0].date_from);
      this.lanzamiento = link[0].date_from != null ? 'enabled' : 'disabled';
      this.stateLanzamiento =
        link[0].date_from != null ? 'enabled' : 'disabled';
      //this.disabledLanzamiento=link[0].date_from!=null?false:true;
      this.expiracionFecha =
        link[0].date_to != null ? new Date(link[0].date_to) : null;
      this.expiracion = link[0].date_to != null ? 'enabled' : 'disabled';
      this.stateExpiracion = link[0].date_to != null ? 'enabled' : 'disabled';
      //this.disabledExpiracion=link[0].date_to!=null?false:true;
      this.pasarelas = JSON.parse(link[0].pasarela);
      this.mediosPago = JSON.parse(link[0].metodoPago);
      this.nombreArchivo = link[0].url_imgage;
      this.stateTerminosCondiciones =
        link[0].Terms_and_Conditions != null ? 'enabled' : 'disabled';
      this.terminosCheck =
        link[0].Terms_and_Conditions ==
        this.$store.state.appConfig.projecto.terms
          ? 'disabled'
          : 'enabled';
      this.permanent = link[0].permanent == 1 ? true : false;
      this.descuentoEnabled = link[0].enableDiscount == 1 ? true : false
      
      for (let i = 0; i < link[0].field.length; i++) {
        let paraPlanilla = {
          title: link[0].field[i].name,
          col: link[0].field[i].column_size,
        };
        this.list2.push(paraPlanilla);
      }

      this.planillaEnBlanco = this.showDynamicForm();
      console.log('planille', this.planillaEnBlanco)
      console.log('list2:', this.list2)
      this.nuevaPlantilla = this.list2;
      this.isNewTemplateActive = true;
      this.buttonDisabled = true
      
      var me = this;
        me.showMediosPago = me.verificaPasarela();
        this.$store.commit('appConfig/changeLoading', false);
    },
    formatDate(date, format) {
      const map = {
        MM: date.getMonth() + 1,
        dd: date.getDate(),
        //yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear(),
        hh: date.getHours(),
        mm: date.getMinutes(),
        ss: date.getSeconds(),
      };
      return format.replace(/MM|dd|yyyy|hh|mm|ss/gi, (matched) => map[matched]);
    },
    async handleAdd() {
      this.handleField()
      this.$store.commit('appConfig/changeLoading', true);

      const token = localStorage.getItem('token');
      var fechaHoy = this.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
      var pasarelaStringify = JSON.stringify(this.pasarelas);
      var metodoPagoString = JSON.stringify(this.mediosPago);
      // id: this.idLink,

      var data = JSON.stringify({
        name: this.producto,
        textPromo: this.textoPromocional,
        description: this.descripcion,
        type: this.tipoPago,
        url_imgage: this.imagen,
        date_from:
          this.lanzamiento === 'enabled'
            ? this.formatDate(
                new Date(this.lanzamientoFecha),
                'yyyy-MM-dd hh:mm:ss'
              )
            : fechaHoy,
        date_to:
          this.expiracion === 'enabled'
            ? this.formatDate(
                new Date(this.expiracionFecha),
                'yyyy-MM-dd hh:mm:ss'
              )
            : null,
        id_project: this.$store.state.appConfig.projecto.id,
        amount: this.importe,
        currency: parseInt(this.moneda),
        callback: this.callbackUrl,
        currencyString: this.currencyString,
        enableDiscount: this.descuentoEnabled == true ? 1 : 0,
        priceString: this.priceString,
        permanent:this.permanent ? 1 : 0,
        pasarela: pasarelaStringify,
        metodoPago: metodoPagoString,
        Terms_and_Conditions: this.terminosCondiciones,
        periodicidad: this.periodicidad ? this.periodicidad[0].value : null,
        diasPrueba: this.diasPrueba ? this.diasPrueba.value : null,
        link_field: this.planillaEnBlanco != this.list2 ? this.planillaEnBlanco : this.list2,
      });
      console.log('data:::', JSON.parse(data))

      var id = this.idLink;
      var config = {
        method: 'put',
        url: `${backendUrl}/link-pago/edit/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      try {
        const res = await axios(config);

        const links = await axios({
          method: 'get',
          url: `${backendUrl}/link-pago/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.$store.commit('appConfig/saveLinksDePago', links.data.link);
       
        this.$store.commit('appConfig/changeLoading', false);
        this.showLinkCreadoExitosoToast('success');
       this.$router.push('/dashboard/linksPago');
       
      } catch (error) {
        this.$store.commit('appConfig/changeLoading', false);

      }
    },
    handleField() {
      this.$store.commit('appConfig/changeLoading', true);

      this.planillaEnBlanco = Object.assign([]);

      if(!this.list2.length) {
        this.showGuardarPlantillaBlancoErrorToast('danger');
        this.$store.commit('appConfig/changeLoading', false);
        return;
      }

      for (let i = 0; i < this.list2.length; i++) {
        let paraPlanilla = {
          name: this.list2[i].title,
          column_size: this.list2[i].col,
          regular_exp: 'regular',
          required: 1,
        };

        this.planillaEnBlanco.push(paraPlanilla);
      }

      if (this.list2.length == this.planillaEnBlanco.length)
        this.showGuardarPlantillaBlancoSuccessToast('success');
      else
        this.showGuardarPlantillaBlancoErrorToast('danger');

      this.$store.commit('appConfig/changeLoading', false);

      this.disableButtonSaveTemplate = true;

      this.isNewTemplateActive = true;
    },
    showGuardarPlantillaBlancoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Guardar plantilla en blanco',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showGuardarPlantillaBlancoSuccessToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha guardado la plantilla exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    onUnpublishedChange({ added }) {
      if (added.newIndex != 0 && this.list2.length > 1) {
        var aux = this.list2[added.newIndex];
        this.list2.splice(added.newIndex, 1);
        this.list2.push(aux);
        this.disableButtonSaveTemplate = false;
        this.buttonDisabled = false
      }
    },
    cargarProyecto() {
      this.proyectoActual = this.$store.state.appConfig.proyectosTotales.filter(
        (x) => {
          if (x.id === 61) return x;
        }
      )[0];
    },
    // async getSavedTemplates() {
    //   let aux;
    //   if (
    //     this.$store.state.appConfig.plantillasLinksPago != null &&
    //     this.$store.state.appConfig.plantillasLinksPago.length > 0
    //   ) {
    //     aux = this.$store.state.appConfig.plantillasLinksPago;
    //   } else {
    //     let idProject = this.$store.state.appConfig.projecto.id;
    //     const token = localStorage.getItem('token');

    //     //TRAER PLANTILLAS DE LINKS DE PAGO
    //     const responseP = await axios({
    //       method: 'get',
    //       url: `${backendUrl}/template/${idProject}`,
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });

    //     this.$store.commit(
    //       'appConfig/savePlantillasLinksDePago',
    //       responseP.data
    //     );

    //     aux = this.$store.state.appConfig.plantillasLinksPago;
    //   }

    //   //

    //   for (let index = 0; index < aux.length; index++) {
    //     this.optionsPlantilla.push({
    //       value: aux[index].idTemplate,
    //       text: aux[index].nameTemplate,
    //       fields: aux[index].fields,
    //     });
    //   }
    // },
    async getSavedTemplates() {
      const idProject = this.$store.state.appConfig.projecto.id;
      const token = localStorage.getItem('token');

      let templates;

      //TRAER PLANTILLAS DE LINKS DE PAGO
      try {
        templates = await axios({
          method: 'get',
          url: `${backendUrl}/template/${idProject}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        return error;
      }
      
      templates.data.forEach(currentTemplate => {
        this.optionsPlantilla.push({
          value: currentTemplate.idTemplate,
          text: currentTemplate.nameTemplate,
          fields: currentTemplate.fields,
        });
      })
    },
    showGenericToast({variant, title, text, icon}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant,
        },
      });
    },

    confirmTemplate() {
      this.isNewTemplateActive = false;

      let templates = this.optionsPlantilla;

      let templateToShow = templates.find(
        (element) => element.value == this.selectedPlantilla
      );

      for (let index = 0; index < templateToShow.fields.length; index++) {
        this.list2.push({
          title: templateToShow.fields[index].name,
          col: templateToShow.fields[index].column_size,
        });
      }

      this.planillaEnBlanco = [];
      for (let i = 0; i < this.list2.length; i++) {
        let paraPlanilla = {
          name: this.list2[i].title,
          column_size: this.list2[i].col,
          regular_exp: 'regular',
          required: 1,
        };
        this.planillaEnBlanco.push(paraPlanilla);
      }

      this.isNewTemplateActive = true;
    },
    showSelectionFields() {
      for (let index = 0; index < this.optionsPlantilla.length; index++) {
        if (this.optionsPlantilla[index].value == this.selectedPlantilla) {
          this.selectionFields = this.optionsPlantilla[index].fields;
        }
      }
    },
  },

  props: {},

  watch: {
    pasarelas: function() {
      this.showMediosPago = this.verificaPasarela()
    },
  },

  data() {
    return {
      addedNew:false,
      buttonDisabled: false,
      callbackUrl: "",
      descuentoEnabled:false,
      productoActivo: null,
      modalCancelarShow: false,
      proyectoActual: {},
      currencyString:'',
      priceString:'',
      dismissSecs: 5,
      dismissCountDown: 0,
      dismissSecsImage: 5,
      dismissCountDownImage: 0,
      dismissSecsTemplate: 5,
      dismissCountDownTemplate: 0,
      dir: 'ltr',
      nuevaPlantilla: null,
      
      //HABILITAR COMPONENTES
      stateLanzamiento: 'enabled',
      stateExpiracion: 'enabled',
      stateTerminosCondiciones: 'disabled',
      lanzamientoMinimo: new Date(Date.now()),
      lanzamientoMaximo: null,
      expiracionFecha: null,
      //DATOS LINK
      linkData: null,
      idLink: -1,
      permanent:null,
      optionPasarelas: [],
      optionMediosPago: [
        { text: 'Tarjeta de Crédito', value: 1, pasarela: [1, 2] },
        { text: 'Tarjeta de Débito', value: 2, pasarela: [1] },
        { text: 'Efectivo', value: 3, pasarela: [1] },
        { text: 'Transferencia', value: 4, pasarela: [1] },
        { text: 'Entel', value: 5, pasarela: [3] },
        { text: 'Movistar', value: 6, pasarela: [3] },
      ],
      producto: null,
      textoPromocional: null,
      descripcion: null,
      imagen: null,
      lanzamiento: 'enabled',
      lanzamientoFecha: new Date(),
      expiracion: 'enabled',
      expiracionFecha: null,
      tipoPago: null,
      importe: null,
      moneda: null,
      periodicidad: '',
      pasarelas: [],
      mediosPago: [],
      metodoPago: [],
      terminosCheck: '',
      terminosCondiciones: null,
      planilla: null,
      planillaEnBlanco: [],
      errorDate: false,
      diasPrueba: '',
      diasPruebaActive: false,
      nombreArchivo: 'Elegir archivo',

      showMediosPago: [],

      //FORMULARIO
      list1: [
        { title: 'Nombre', col: 'col-12' },
        { title: 'Apellido', col: 'col-12' },
        { title: 'Email', col: 'col-12' },
        { title: 'Teléfono', col: 'col-12' },
        { title: 'Dirección', col: 'col-12' },
        { title: 'RUT', col: 'col-12' },
        { title: 'Nombre de Empresa', col: 'col-12' },
        { title: 'RUT de Empresa', col: 'col-12' },
        { title: 'Giro de Empresa', col: 'col-12' },
        { title: 'Dirección de Empresa', col: 'col-12' },
        { title: 'Email de Empresa', col: 'col-12' },
      ],
      list2: [],
      isVisible: false,
      isNewTemplateActive: false,

      //Campos obligatorios
      aceptadoProducto: null,
      aceptadoTextoProm: null,
      aceptadoDescripcion: null,
      aceptadoImagen: true,
      aceptadoTipoPago: true,
      aceptadoImporte: null,
      aceptadoMoneda: null,
      aceptadoMedioPago: true,
      aceptadoPasarelas: true,
      aceptadoTerminosCondiciones: null,
      aceptadoPeriodicidad: null,
      aceptadoDiasPrueba: null,
      selectedPlantilla: null,
      optionsPlantilla: [],
      modalElegirPlantillaShow: false,
      selectionFields: null,
      disableButtonSaveTemplate: false
    };
  },

  computed: {
    disabledLanzamiento() {
      this.lanzamientoFecha == null;
      return this.stateLanzamiento === 'disabled';
    },
    disabledExpiracion() {
      this.expiracionFecha == null;
      return this.stateExpiracion === 'disabled';
    },
    disabledTerminosCondiciones() {
      this.terminosCondiciones == null;
      return this.stateTerminosCondiciones === 'disabled';
    },
  },

  setup() {
    const optionPeriodicidad = [
      { title: 'Diaria', value: 1 },
      { title: 'Semanal', value: 2 },
      { title: 'Quincenal', value: 3 },
      { title: 'Mensual', value: 4 },
      { title: 'Trimestral', value: 5 },
      { title: 'Semestral', value: 6 },
      { title: 'Anual', value: 7 },
    ];

    const optionDiasPrueba = [
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
    ];

    const rolReintegro = [
      {
        text: 'Calendarizado',
        value: 'Calendarizado',
      },
      {
        text: 'Fijo',
        value: 'Fijo',
      },
    ];

    const yesNoOptions = [
      { text: 'Sí', value: 'Sí' },
      { text: 'No', value: 'No' },
    ];

    const terminosCondicionesOptions = [
      {
        text: 'Predeterminado',
        value: 'Predeterminado',
      },
      { text: 'Personalizados', value: 'Personalizados' },
    ];

    const tipoPagoOptions = [
      { text: 'Pago único', value: 1 },
      { text: 'Suscripción', value: 2 },
    ];

    const mediosOptions = [
      { text: 'Kushki', value: 1 },
      { text: 'Stripe', value: 2 },
      { text: 'Suscripciones Móviles', value: 3 },
    ];

    return {
      optionPeriodicidad,
      optionDiasPrueba,
      rolReintegro,
      yesNoOptions,
      tipoPagoOptions,
      mediosOptions,
      terminosCondicionesOptions,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#selectProductos .vs__selected-options .vs__selected {
  background: $info;
  border-radius: 20px;
}

.action-button {
  border: none;
  background: transparent;
  width: 18px;
}

#cancel-button {
  width: 180px;
}

.dynamic-form-buttons {
  width: 180px;
}

#choose-empty-template-button {
  width: 210px;
}

#choose-template-button {
  width: 180px;
  margin-right: 20px;
}

#button-guardar {
  width: 146.22px;
}

.data-items-size {
  font-size: 12px;
}

::v-deep .table th {
  text-align: center !important;
}

#label-tarifa {
  font-size: 15px;
  font-weight: bold;
}

.modal-button-size {
  width: 166px;
}

#agregar-button-modal {
  margin-left: 14px;
}

#label-confirmacion {
  margin-left: 12%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

#label-confirmacion-cancelar {
  margin-left: 22%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

#card-monto {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding-left: 28px;
  padding-right: 18px;
  padding-bottom: 16px;
  padding-top: 28px;
  margin-top: 24px;
}

.ancho-date-picker {
  width: 96%;
}

.margen-medio-pago {
  margin-left: 1px;
}

.custom-file-label::after {
  background: $info !important;
  color: #ffffff;
}

.custom-file-label::after {
  content: 'Buscar';
}

input[type='file'] {
  display: none;
}

#card-dynamic-form {
  background: #eeeeee;
  border-color: $dark;
  border-radius: 6px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 8px;
  padding-top: 16px;
  margin-top: 20px;
  height: calc(100% - 37px - 48px);
}

.no-padding {
  padding: 0;
  padding-left: 8px;
  margin-bottom: 2px;
}

#label-campos {
  margin-top: 4px;
  margin-bottom: 18%;
}

.active-empty-template {
  background: $primary;
  color: white;
}

.buttonGuardarPlantilla {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formulario-label {
  margin-top: 4px;
  margin-bottom: 12px;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  /* position: absolute; */
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
